import type { FieldEventHooks, FieldData } from '@shared/types';

/**
 * Hosted fields configurations in Client-Server format:
 *
 * - configuration of each fields: styling, fields event-hooks
 * - configuration of the form: form's event-hooks
 */
export interface HostedFieldsClientServerConfig {
    server: HostedFieldsConfigServer,
    client: HostedFieldsConfigClient;
}

/**
 * Real Hosted fields configurations which is used by the SDK.
 * On the surface, consumer provides the HostedFieldsClientServerConfig, it is map to this class after validation.
 */
export interface HostedFieldsConfig extends HostedFieldsConfigServer, HostedFieldsConfigClient {
    /**
    * Consumer don't need to use this option.
    * The SDK usually know where the fields are hosted. This option asks the SDK to use another server as host.
    */
    iframeUrl?: string;
}

/**
 * type guard https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export function isHostedFieldsClientServerConfig(config: HostedFieldsClientServerConfig | HostedFieldsConfig): config is HostedFieldsClientServerConfig {
    return (config as HostedFieldsClientServerConfig).client !== undefined;
}

export interface HostedFieldsConfigServer {
    /**
     * This token is required when end-user submit the form. You will have to call the Lemonway's API MoneyInWebInit function (from your Server) to generate this token.
     */
    webkitToken: string;
}

export interface HostedFieldsConfigClient extends FormEventHooks {
    holderName?: Field;
    cardNumber?: Field;
    expirationDate?: Field;
    cvv: Field;
    registerCardCheckbox?: Field;
    /**
     * configure the name of the Css toggling class
     */
    cssClass?: ContainerTogglingClass;
    /**
     * Disable Synthetic events and synthetic values. You can use event-hooks as alternative.
     */
    disableSyntheticEvents?: boolean;
}

/**
 * The class toggling for the container element (outside iframe). User can change these default value via hosted configurations.
 */
export const enum ContainerTogglingClassDefault {
    valid = 'lw-hosted-fields-valid',
    invalid = 'lw-hosted-fields-invalid',
    notEmpty = 'lw-hosted-fields-not-empty',
    focused = 'lw-hosted-fields-focused',
    checked = 'lw-hosted-fields-checked'
}

/**
 * Configuration of a individual field: placeHolder, style (dark, light), event-hooks..
 */
export interface Field extends FieldData, FieldEventHooks { }

/**
 * change the default Css toggling class
 */
export interface ContainerTogglingClass {
    /**
     * default value is 'lw-hosted-fields-valid'
     */
    valid?: string | null;
    /**
     * default value is 'lw-hosted-fields-invalid'
     */
    invalid?: string | null;
    /**
     * default value is 'lw-hosted-fields-not-empty'
     */
    notEmpty?: string | null;
    /**
     * default value is 'lw-hosted-fields-focused'
     */
    focused?: string | null;
    /**
     * default value is 'lw-hosted-fields-checked'
     */
    checked?: string | null;
}

export interface FormEventHooks {
    /**
     * this hook in called when all the iframes content are ready
     */
    onFormReady?(source: HTMLFormElement | null): void;
    /**
     * this hook is called when all the fields become valid, or 1 of the field become invalid
     * @param isValid is true if all fields are valid
     */
    onFormValidityChanged?(source: HTMLFormElement | null, isValid: boolean): void;
}
