export const enum FieldKind {
    HolderName = 'HolderName',
    CardNumber = 'CardNumber',
    ExpirationDate = 'ExpirationDate',
    Cvv = 'Cvv',
    RegisterCardCheckbox = 'RegisterCardCheckbox'
}

export const enum MessageType {
    IframeContentConfigRequest = 'IframeContentConfigRequest',
    FocusStateChanged = 'FocusStateChanged',
    InputValueChanged = 'InputValueChanged',
    EmptyStateChanged = 'EmptyStateChanged',
    ValidityStateChanged = 'ValidityStateChanged',
    FocusPreviousElementRequest = 'FocusPreviousElementRequest',
    IframeContentReady = 'IframeContentReady',
    SetPlaceHolder = 'SetPlaceHolder',
    SetStyle = 'SetStyle',
    RequestSubmission = 'RequestSubmission',
    RequestForValue = 'RequestForValue',
    RequestValidate = 'RequestValidate',
}

/**
 * config of the document content inside the iframe
 */
export interface IframeContentConfig extends FieldData {
    kind: FieldKind;
    uuid: string;
    partnerOrigin: string;
    partnerUrl: string;
}

/**
 * Configuration of an individual hosted field.
 */
export interface FieldData {
    /**
     * Id of the container which the hosted field is mounted to
     */
    containerId: string;
    /**
     * Inject CSS style of the field. It will be sanitized before injecting.
     */
    style?: string;
    /**
     * place holder of the field
     */
    placeHolder?: string;
    /**
     * if this flag is set to true then the iframe will print to the console the sanitized style after injecting.
     * if the style you are injecting is not taking effect, then probably something is wrong with the sanitized version.
     * you can activate this flag to see what is happening with your css after sanitizing.
     */
    debugStyle?: boolean;
}

/**
 * message sent by sdk, which is captured/handled by the iframe
 */
export interface SdkMessage {
    type: MessageType;
    /**
     * uuid of the target. In fact it is not really needed because
     * the Sdk often targeting directly the specifics iframe.
     * This is just for a little more security which enforce
     * The sdk to provide the right uuid in order to communicate with the iframe
     */
    uuid: string;
}

/**
 * the Iframe.svelte dispatch these events to the parent container
 */
export interface Dispatchable {
    kind: FieldKind;
    /**
     * current masked value of the input inside the iframe
     */
    valueMasked: string;
    /**
     * current (real) value of the input inside the iframe. if it was a sensitive information then this propertie will got an "Undefined" value.
     * the parent container could look at the "valueMasked" in this case.
     */
    value?: string | boolean;
}

/**
 * message sent by iframe, which is captured/handled by the sdk
 */
export interface IframeMessage {
    type: MessageType;
    /**
     * uuid of the target
     */
    uuid: string;
}

export interface IframeContentConfigRequest extends IframeMessage {
    type: MessageType.IframeContentConfigRequest;
}

export interface FocusStateChanged extends IframeMessage, Dispatchable {
    type: MessageType.FocusStateChanged;
    focused: boolean;
}

export interface InputValueChanged extends IframeMessage, Dispatchable {
    type: MessageType.InputValueChanged;
}

export interface EmptyStateChanged extends IframeMessage, Dispatchable {
    type: MessageType.EmptyStateChanged;
    /**
     * if true then the hosted field is switching from NotEmpty to empty
     */
    isEmpty: boolean;
}

export interface ValidityStateChanged extends IframeMessage, Dispatchable {
    type: MessageType.ValidityStateChanged;
    /**
     * the validity state null means unknown. when user is not finish typing
     */
    isValid: boolean | null;
}

export interface FocusPreviousElementRequest extends IframeMessage {
    type: MessageType.FocusPreviousElementRequest;
}

export interface IframeContentReady extends IframeMessage, Dispatchable {
    type: MessageType.IframeContentReady;
    /**
     * duration spent to setup the IframeContent in millisecond
     */
    elapsedTime: number;
}

export interface SetPlaceHolder extends SdkMessage {
    type: MessageType.SetPlaceHolder;
    newValue: string;
}
export interface SetStyle extends SdkMessage {
    type: MessageType.SetStyle;
    newValue: string;
}

export interface RequestSubmission extends SdkMessage {
    type: MessageType.RequestSubmission;
    /**
     * uuids of all the fields
     */
    uuids: string[];
    /**
     * token of the pending payment which partner will have to prepare on the server side
     */
    webkitToken: string;
}

/**
 * Event hooks of an individual hosted field.
 */
export interface FieldEventHooks {
    /**
     * Fired when the hosted field value changed.
     * @param source: container element
     */
    onInputValueChanged?(
        source: HTMLElement,
        event: CustomEvent<InputValueChanged>
    ): void;
    /**
     * Fired when the hosted field focus is changed (user enter or leave the field).
     * @param source: container element
     */
    onFocusStateChanged?(
        source: HTMLElement,
        event: CustomEvent<FocusStateChanged>
    ): void;
    /**
     * Fired when the hosted field validity changed (eg. from 'invalid' to 'valid' or from 'unknown' to 'valid'...)
     * @param source: container element
     */
    onFieldValidityChanged?(
        source: HTMLElement,
        event: CustomEvent<ValidityStateChanged>
    ): void;

    /**
     * Fired when the hosted field value switch from empty to notEmpty or reverse (from notEmpty to empty).
     * @param source: container element
     */
    onEmptyStateChanged?(
        source: HTMLElement,
        event: CustomEvent<EmptyStateChanged>
    ): void;
}

/**
 * Allow to create a redirection by form submission.
 * We use this data to insert a form element to the DOM and submit it causing the redirection effect.
 */
export interface WebRedirection {
    Verb: 'GET' | 'POST';
    Uri: string;
    /**
     * the data we will put in the generated form, and be sent along with the redirection.
     */
    Fields: Record<string, string>;
}

/**
 * response for RequestSubmission
 */
export interface SubmissionResult {
    webRedirection?: WebRedirection;
    comment?: any;
}

/**
 * The SDK sends this message to the iframe to explicitly ask for Validation.
 * The validation will switch appropriate event and toggling classes. And empty value won't be
 * treated as Unknown validity.
 */
export interface RequestValidate extends SdkMessage {
    type: MessageType.RequestValidate;
}

/**
 * Response for RequestValidate. The iframe won't return unknown validity. The value of isValid is true or false.
 */
export interface ValidateResult {
    isValid: boolean;
}
