import {
    MessageType,
    type EmptyStateChanged,
    type FieldEventHooks,
    type FocusStateChanged,
    type IframeContentReady,
    type InputValueChanged,
    type ValidityStateChanged,
    type Dispatchable,
} from '@shared/types';
import type FormState from './form-state';
import type Iframe from './Iframe.svelte';
import { ContainerTogglingClassDefault, type ContainerTogglingClass, type FormEventHooks } from './types';

/**
 * Helper of the SDK to handle UI Events coming from an iframe.svelte component
 */
export function startListeningUIEvents(
    formState: FormState,
    container: HTMLElement,
    iframe: Iframe,
    fieldEventHooks: FieldEventHooks | null,
    formEventHooks: FormEventHooks | null,
    allowSyntheticEvents: boolean,
    containerTogglingClassConfig?: ContainerTogglingClass
) {
    const containerTogglingClass = enrichWithDefaultValue(
        containerTogglingClassConfig
    );

    iframe.$on(MessageType.FocusStateChanged, (e) =>
        handleFocusStateChanged(e)
    );
    iframe.$on(MessageType.InputValueChanged, (e) =>
        handleInputValueChanged(e)
    );
    iframe.$on(MessageType.EmptyStateChanged, (e) =>
        handleEmptyStateChanged(e)
    );
    iframe.$on(
        MessageType.IframeContentReady,
        (_: CustomEvent<IframeContentReady>) => {
            if (formState.isFormReady()) {
                dispatchSyntheticEvent(
                    formState.getParentForm(),
                    new Event('ready')
                );
                //run hook
                if (formEventHooks?.onFormReady) {
                    formEventHooks.onFormReady(
                        formState.getParentForm()
                    );
                }
            }
        }
    );
    iframe.$on(
        MessageType.ValidityStateChanged,
        (e: CustomEvent<ValidityStateChanged>) => {
            handleFieldValidityStateChanged(e);
            triggerFormValidityChanged();
        }
    );

    function triggerFormValidityChanged() {
        const { isFormValid, isFormValidityChanged } =
            formState.refreshFormValidity();
        if (isFormValidityChanged) {
            dispatchSyntheticEvent(
                formState.getParentForm(),
                new Event(isFormValid ? 'valid' : 'invalid')
            );
            if (formEventHooks?.onFormValidityChanged) {
                formEventHooks.onFormValidityChanged(
                    formState.getParentForm(),
                    isFormValid
                );
            }
        }
    }

    function handleInputValueChanged(e: CustomEvent<InputValueChanged>) {
        setSyntheticValue(e.detail);
        //toggle container class
        if (e.detail.value === true) {
            addContainerClass(containerTogglingClass.checked);
        } else {
            removeContainerClass(containerTogglingClass.checked);
        }

        //dispatchSyntheticEvent
        const inputEvent = new InputEvent('input', {
            data: e.detail.valueMasked,
            inputType: iframe.config.kind,
        });
        dispatchSyntheticEvent(container, inputEvent);

        //run hook
        if (fieldEventHooks?.onInputValueChanged) {
            fieldEventHooks.onInputValueChanged(container, e);
        }
    }

    function handleEmptyStateChanged(e: CustomEvent<EmptyStateChanged>) {
        setSyntheticValue(e.detail);
        //toggle container class
        if (e.detail.isEmpty) {
            removeContainerClass(containerTogglingClass.notEmpty);
            removeContainerClass(containerTogglingClass.invalid);
            removeContainerClass(containerTogglingClass.valid);
        } else {
            addContainerClass(containerTogglingClass.notEmpty);
        }
        dispatchSyntheticEvent(
            container,
            new Event(e.detail.isEmpty ? 'empty' : 'not-empty')
        );
        //run hook
        if (fieldEventHooks?.onEmptyStateChanged) {
            fieldEventHooks.onEmptyStateChanged(container, e);
        }
    }

    function handleFieldValidityStateChanged(
        e: CustomEvent<ValidityStateChanged>
    ) {
        const isValid = e.detail.isValid;
        switch (isValid) {
            case true:
                dispatchSyntheticEvent(container, new Event('valid'));
                removeContainerClass(containerTogglingClass.invalid);
                addContainerClass(containerTogglingClass.valid);
                break;
            case false:
                dispatchSyntheticEvent(
                    container,
                    new Event('invalid')
                );
                addContainerClass(containerTogglingClass.invalid);
                removeContainerClass(containerTogglingClass.valid);
                break;
            default: //case null
                removeContainerClass(containerTogglingClass.invalid);
                removeContainerClass(containerTogglingClass.valid);
        }
        //run hook
        if (fieldEventHooks?.onFieldValidityChanged) {
            fieldEventHooks.onFieldValidityChanged(container, e);
        }
    }

    function handleFocusStateChanged(e: CustomEvent<FocusStateChanged>) {
        if (e.detail.focused) {
            addContainerClass(containerTogglingClass.focused);
            dispatchSyntheticEvent(
                container,
                new FocusEvent('focus', {
                    relatedTarget: container,
                })
            );
        } else {
            removeContainerClass(containerTogglingClass.focused);
            dispatchSyntheticEvent(
                container,
                new FocusEvent('blur', {
                    relatedTarget: container,
                })
            );
        }

        //run Hook
        if (fieldEventHooks?.onFocusStateChanged) {
            fieldEventHooks.onFocusStateChanged(container, e);
        }
    }

    function addContainerClass(className?: string | null) {
        if (!className) return;
        container.classList.add(className);
    }

    function removeContainerClass(className?: string | null) {
        if (!className) return;
        container.classList.remove(className);
    }

    function dispatchSyntheticEvent(target: Element | null, event: Event) {
        if (!allowSyntheticEvents || !target) return;
        target.dispatchEvent(event);
    }

    function setSyntheticValue(e: Dispatchable) {
        if (!allowSyntheticEvents || !container) return;
        const valueMasked = e.valueMasked;
        (container as HTMLInputElement).value = valueMasked;
        if (isBoolean(e.value)) {
            (container as HTMLInputElement).checked = e.value as boolean;
        }
        function isBoolean(val?: string | boolean) {
            return val === false || val === true;
        }
    }
}

/**
 * Compute the ContainerTogglingClass configuration to fallback to the default value
 * if partner did not defnie it, or else disable the toogling effect if partner explicitly
 * configure Null or empty value
 * @param c raw ContainerTogglingClass
 * @returns ContainerTogglingClass with fallback value
 */
export function enrichWithDefaultValue(
    c?: ContainerTogglingClass
): ContainerTogglingClass {
    return {
        valid: fallbackIfNotSet(
            c?.valid,
            ContainerTogglingClassDefault.valid
        ),
        invalid: fallbackIfNotSet(
            c?.invalid,
            ContainerTogglingClassDefault.invalid
        ),
        notEmpty: fallbackIfNotSet(
            c?.notEmpty,
            ContainerTogglingClassDefault.notEmpty
        ),
        focused: fallbackIfNotSet(
            c?.focused,
            ContainerTogglingClassDefault.focused
        ),
        checked: fallbackIfNotSet(
            c?.checked,
            ContainerTogglingClassDefault.checked
        ),
    };
}

/**
 * fallback to default value if the targetValue is undefined
 */
function fallbackIfNotSet(
    targetValue: string | null | undefined,
    defaultValue: string
): string | null {
    return targetValue === undefined ? defaultValue : targetValue;
}
