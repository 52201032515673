import type { WebRedirection } from '@shared/types';

/**
 * Find all label attached to the container
 * @param containerId id of the container element
 */
export function findLabelsFor(
    containerId: string
): NodeListOf<HTMLLabelElement> {
    return document.querySelectorAll(`label[for="${containerId}"]`);
}

/**
 * Find first label attached to the container
 * @param containerId id of the container element
 */
export function findLabelFor(containerId: string): HTMLLabelElement | null {
    return document.querySelector(`label[for="${containerId}"]`);
}

/**
 * Find the first parent (or ancestor) form element of the child element
 */
export function findParentForm(child: Element | null): HTMLFormElement | null {
    if (!child) return null;
    let cursor = child.parentElement;
    while (cursor) {
        if (cursor.tagName === 'FORM') return cursor as HTMLFormElement;
        cursor = cursor.parentElement;
    }
    return null;
}

function isNullOrEmpty(records: Record<string, string>) {
    //check null
    if (!records) return true;
    //check empty
    return Object.keys(records).length == 0;
}

/**
 * Perform a Redirection by form submission. This function will insert a form element to the DOM and submit it causing the redirection effect.
 * Unlike a simple redirection where the target server usually receive a GET without data. This function also allow you to make a POST redirection with POST data.
 */
export function redirectByFormSubmission(webRedirection: WebRedirection) {
    if (webRedirection.Verb && webRedirection.Verb.toUpperCase() === "GET"
        && isNullOrEmpty(webRedirection.Fields)) {
        //execute "normal redirection technique"
        window.location.assign(webRedirection.Uri);
        return;
    }
    //execute "form redirection technique"
    const form = document.createElement('form');

    form.setAttribute('method', webRedirection.Verb);
    form.setAttribute('action', webRedirection.Uri);

    if (webRedirection.Fields) {
        for (const key in webRedirection.Fields) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', webRedirection.Fields[key]);
            form.append(input);
        }
    }
    document.body.append(form);
    form.submit();
}

// TODO:
// 1) create playwright test specifically for focusPreviousElement function
// 2) optimize them (maybe merge them together?)
// 3) display:none is not enough we also need to filter out hidden element with attribute hidden=1

function getFocusableElements(containerElement: Document | HTMLElement) {
    /* we need to keep tabindex -1 at first as it might be the active element,
    it is then filtered after if it is tabindex -1 and is not active element */
    const focusableSelectors =
        'a[href]:not([disabled]), area[href]:not([disabled]), input:not([disabled]), select:not([disabled]), ' +
        'textarea:not([disabled]), button:not([disabled]), *[tabindex]:not([disabled])';
    const focusableElements = Array.prototype.filter.call(
        containerElement.querySelectorAll(focusableSelectors),
        (element: HTMLElement) => {
            // only take elements on screen & the active element and not display:none
            return (
                (element.offsetWidth > 0 ||
                    element.offsetHeight > 0 ||
                    element === document.activeElement ||
                    element.getAttribute('tabindex') !== '-1') &&
                window.getComputedStyle(element).display !== 'none'
            );
        }
    );
    const focusedElementIndex = focusableElements.indexOf(
        document.activeElement
    );
    return { focusableElements, focusedElementIndex };
}

export function focusPreviousElement(containerElement: Document | HTMLElement) {
    if (document.activeElement) {
        const { focusableElements, focusedElementIndex } =
            getFocusableElements(containerElement);
        if (focusedElementIndex > -1) {
            let previousElement = null;
            if (focusedElementIndex === 0) {
                previousElement =
                    focusableElements[focusableElements.length - 1];
            } else {
                previousElement = focusableElements[focusedElementIndex - 1];
            }

            if (previousElement) {
                previousElement.focus();
            }
        }
    }
}
