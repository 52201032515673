import Iframe from './Iframe.svelte';
import {
    FieldKind,
    type SubmissionResult,
    type WebRedirection,
} from '@shared/types';
import { isHostedFieldsClientServerConfig, type Field, type HostedFieldsClientServerConfig, type HostedFieldsConfig } from './types';
import {
    createIframeContentConfig,
    getFieldEventHooks,
    getFormEventHooks,
    getHostedFieldsConfig,
} from './mapping';
import { startListeningUIEvents } from './ui-event-handlers';
import FormState from './form-state';
import { findLabelsFor, findLabelFor, redirectByFormSubmission } from './utils';

const sdkRelativeUrl =
    import.meta.url ?? document.currentScript?.getAttribute('src');
const sdkAbsoluteUrl = sdkRelativeUrl
    ? new URL(sdkRelativeUrl, window.location.href).href
    : undefined;

/**
 * This class is exposed as a global `LwHostedFields` object. It is the main SDK class entry point.
 * It helps to configure all the hosted fields, mount them to your page, gather and manage all the hosted fields together as a form, submit the form...
 */
export default class LwHostedFields {
    private readonly config: HostedFieldsConfig;
    private readonly iframes = new Map<FieldKind, Iframe>();
    private readonly formState = new FormState();
    private readonly iframeUrl: string;
    private mountLocked = false;

    constructor(config: HostedFieldsClientServerConfig | HostedFieldsConfig) {
        this.config = isHostedFieldsClientServerConfig(config) ? getHostedFieldsConfig(config) : config;
        this.iframeUrl = this.getIframeUrl();
    }

    /**
     * Create the iframe svelte component and make some initialization
     */
    private createIframe(kind: FieldKind, field?: Field): Iframe | null {
        if (!field) return null;
        const iframeContentConfig = createIframeContentConfig(kind, field);
        if (!iframeContentConfig) return null;

        const containerId = iframeContentConfig.containerId;
        const container = document.getElementById(containerId);
        if (!container) {
            console.error(
                `Unable to mount the component ${kind} to a non existing container #${containerId}`
            );
            return null;
        }

        try {
            const iframe = new Iframe({
                target: container,
                props: {
                    config: iframeContentConfig,
                    iframeUrl: this.iframeUrl,
                },
            });
            this.formState.updateParentForm(container);
            startListeningUIEvents(
                this.formState,
                container,
                iframe,
                getFieldEventHooks(field),
                getFormEventHooks(this.config),
                this.config.disableSyntheticEvents !== true,
                this.config.cssClass
            );
            this.focusIframeWhenClickOnLabel(containerId, iframe);
            this.setSyntheticPlaceholder(container, field.placeHolder);
            this.iframes.set(kind, iframe);
            return iframe;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * The form is ready if all the fields are ready. A field is ready when the hosted content is loaded and finished the setup process (set the configured place holder, sanitize and inject the configured styles, prepare masked editor, validators and events listeners..)
     * @returns true if all fields are ready
     */
    public isFormReady() {
        return this.formState.isFormReady();
    }

    /**
     * @returns true if all fields are valid
     */
    public isFormValid() {
        return this.formState.isFormValid();
    }

    /**
     * Explicitly trigger the validation on all hosted fields. The empty value won't be treated as Unknown validity.
     * The corresponding validator (on the iframe side) will decide if the empty value is Valid or Invalid.
     * The corresponding validity events, hooks and CSS Class will be toggle accordingly.
     * @returns true if all fields are valid
     */
    public validateForm() {
        return this.formState.validateForm();
    }

    /**
     * return the current validity of the field, return null if the validity is unknown.
     * throw error if field is not mounted
     */
    public isFieldValid(kind: FieldKind): boolean | null {
        return this.getMountedField(kind).getValidity();
    }

    /**
     * find all the &lt;label for=containerId /&gt; elements (all label referencing the container "div")
     * add onclick event, so that when user click on the label then the iframe svelte component will be focused.
     */
    private focusIframeWhenClickOnLabel(containerId: string, iframe: Iframe) {
        const labels = findLabelsFor(containerId);
        labels.forEach((label) =>
            label.addEventListener('click', () => iframe.focus())
        );
    }

    /**
     * Mount all the fields which are configured. If it unable to mount a field then
     * it will log a error and continue to mount other fields.
     */
    public mount() {
        if (this.mountLocked) {
            throw new Error('mount() has been already called.');
        }
        this.mountLocked = true;
        this.createIframe(FieldKind.HolderName, this.config.holderName);
        this.createIframe(FieldKind.ExpirationDate, this.config.expirationDate);
        this.createIframe(FieldKind.CardNumber, this.config.cardNumber);
        this.createIframe(FieldKind.Cvv, this.config.cvv);
        this.createIframe(FieldKind.RegisterCardCheckbox, this.config.registerCardCheckbox);
        this.formState.mountCompleted(this.iframes);
    }

    private getMountedField(kind: FieldKind): Iframe {
        const iframe = this.iframes.get(kind);
        if (!iframe)
            throw new Error(`The Hosted field '${kind}' is not mounted.`);
        return iframe;
    }

    /**
     * it will unmount all hosted-fields from container elements. The LwHostedFields object will no longer useable after dispose.
     */
    public dispose() {
        this.iframes.forEach((iframe) => {
            const container = document.getElementById(iframe.config.containerId);
            if (container) container.innerHTML = '';
        });
    }

    /**
     * change the place holder value of a field (for i18n), you can call it when user change localization on your page
     */
    public setPlaceHolder(kind: FieldKind, newValue: string) {
        const iframe = this.getMountedField(kind);
        iframe.setPlaceHolder(newValue);
        const container = document.getElementById(iframe.config.containerId);
        this.setSyntheticPlaceholder(container, newValue);
    }

    /**
     * set style of a field, old style will be replaced by this new style. You can call it to switch to other theme
     */
    public setStyle(kind: FieldKind, newValue: string) {
        this.getMountedField(kind).setStyle(newValue);
    }

    /**
     * set style of all fields. Old style will be replaced by the new one. You can use this function when user switch to other theme on your page.
     */
    public setStyleAll(newValue: string) {
        this.iframes.forEach((iframe) => {
            iframe.setStyle(newValue);
        });
    }

    /**
     * Submit the form. autoRedirect is true by default. If you need to do something before the redirection, you can
     * - put autoRedirect to false: `const submitResult = lwHostedField.submit(false);`
     * - do your things
     * - then use the function `LwHostedFields.redirectByFormSubmission(submitResult.webRedirection)` to redirect the payer.
     * @param webkitToken this token is prepared on your server side by calling the Lemonway's directkit API
     * @param autoRedirect default = true: if true: redirect user automatically.
     */
    public async submit(
        autoRedirect?: boolean
    ): Promise<SubmissionResult> {
        const isFormValid = await this.formState.validateForm();
        if (!isFormValid)
            throw new Error(
                'Form is not in valid state. Make sure that all fields have valid values. It is recommended to disable the Submit button until end-user completed the form.'
            );
        //now we are sure that `this.iframes` is not a empty collection or else the formState will be invalid
        const iframes = Array.from(this.iframes.values());
        const uuids: string[] = iframes.map((i) => i.config.uuid);
        const webkitToken = this.config.webkitToken as string;
        if (!webkitToken) {
            throw new Error('Missing webkitToken in the configuration');
        }
        const resp = await iframes[0].SubmitForm(uuids, webkitToken);
        if (resp.webRedirection && autoRedirect !== false) {
            LwHostedFields.redirectByFormSubmission(resp.webRedirection);
        }
        return resp;
    }

    private setSyntheticPlaceholder(
        target: HTMLElement | null,
        placeHolder?: string
    ) {
        if (
            !this.config.disableSyntheticEvents !== true ||
            !target ||
            !placeHolder
        )
            return;
        (target as HTMLInputElement).placeholder = placeHolder;
    }

    /**
     * Return the parent payment form. If there are multiple form elements then the first form element of a first (random) hosted field container will be considered as the right one. It is not a requirement to mount the hosted fields inside a `<form>` tag. Hence this function might return `Undefined` in this case.
     */
    public getParentForm(): HTMLFormElement | null {
        return this.formState.getParentForm();
    }

    /**
     * Best effort to locate the iframe URL
     * - Consumer can tell the SDK where the iframeUrl is (in the hosted field config)
     * - Lemonway can tell the SDK  where the iframeUrl is in build time via VITE_IFRAME_URL env.var.
     * - SDK URL can deduce itself the iframe location relative to its `document.currentScript.src`: because in production the SDK and the IFRAME are deployed side by side.
     */
    private getIframeUrl(): string {
        //use the value in the config
        let iframeUrl: string | undefined = this.config.iframeUrl;
        //use the value in build time
        if (!iframeUrl) {
            iframeUrl = import.meta.env.VITE_IFRAME_URL;
        }
        //use the value deduced from the `document.currentScript.src`
        if (!iframeUrl) {
            if (!sdkAbsoluteUrl)
                throw new Error(
                    "the SDK couldn't infer the IFRAME_URL because it does not know its own location"
                );
            iframeUrl = new URL('../../iframe/index.html', sdkAbsoluteUrl).href;
        }
        return iframeUrl;
    }

    /**
     * Perform a Redirection by form submission. This function will insert a form element to the DOM and submit it causing the redirection effect.
     * Unlike a simple redirection where the target server usually receive a GET without data. This function also allow you to make a POST redirection with POST data.
     */
    public static redirectByFormSubmission(webRedirection: WebRedirection) {
        return redirectByFormSubmission(webRedirection);
    }
    /**
     * Find first label attached to the container
     * @param containerId id of the container element
     */
    public static findLabelFor(containerId: string): HTMLLabelElement | null {
        return findLabelFor(containerId);
    }
}
