import { v4 as uuidv4 } from 'uuid';
import type {
    FieldEventHooks,
    FieldKind,
    IframeContentConfig,
} from '@shared/types';
import type { Field, FormEventHooks, HostedFieldsClientServerConfig, HostedFieldsConfig } from './types';

export function createIframeContentConfig(
    kind: FieldKind,
    field?: Field
): IframeContentConfig | null {
    if (!field) return null;
    const result: IframeContentConfig = {
        uuid: uuidv4(),
        kind,
        partnerOrigin: window.location.origin,
        style: field.style,
        containerId: field.containerId,
        placeHolder: field.placeHolder,
        debugStyle: field.debugStyle,
        partnerUrl: window.location.toString(),
    };
    return result;
}

export function getFieldEventHooks(field: Field): FieldEventHooks | null {
    if (!field) return null;
    const result: FieldEventHooks = {
        onInputValueChanged:
            field.onInputValueChanged instanceof Function
                ? field.onInputValueChanged
                : undefined,
        onFocusStateChanged:
            field.onFocusStateChanged instanceof Function
                ? field.onFocusStateChanged
                : undefined,
        onFieldValidityChanged:
            field.onFieldValidityChanged instanceof Function
                ? field.onFieldValidityChanged
                : undefined,
        onEmptyStateChanged:
            field.onEmptyStateChanged instanceof Function
                ? field.onEmptyStateChanged
                : undefined,
    };
    return result;
}

export function getFormEventHooks(
    config: HostedFieldsConfig
): FormEventHooks | null {
    if (!config) return null;
    const result: FormEventHooks = {
        onFormReady:
            config.onFormReady instanceof Function
                ? config.onFormReady
                : undefined,
        onFormValidityChanged:
            config.onFormValidityChanged instanceof Function
                ? config.onFormValidityChanged
                : undefined,
    };
    return result;
}

export function getHostedFieldsConfig(config: HostedFieldsClientServerConfig): HostedFieldsConfig {
    if (!config) throw new Error('Missing hosted-fields config');
    if (!config.client) throw new Error('Missing "client" in the hosted-fields config');
    const result: HostedFieldsConfig = {
        ...config.server,
        ...config.client
    };
    return result;
}
