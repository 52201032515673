import type Iframe from './Iframe.svelte';
import type { FieldKind } from '@shared/types';
import { findParentForm } from './utils';

/**
 * Manage the overall state of the form base on the state of all the mounted Fields.
 * "Mount" state: all the fields are mounted.
 * "Ready" state: the Form is ready when all the fields are ready.
 * "Validity" state: the Form is valid when all the fields are valid
 */
export default class FormState {
    /**
     * this property is set only when all the fields are mounted
     * it contains the complete list of all the mounted components of the form
     */
    private iframes?: Map<FieldKind, Iframe>;

    /**
     * the sdk will call refreshFormValidity() to update this value when detecting validity changed in one of the fields
     */
    private currentFormValidity = false;

    /**
     * @returns true if all fields are ready
     */
    public isFormReady(): boolean {
        //mounting is not finished
        if (!this.iframes) return false;

        //check if one of the field is not ready then return false immediately
        for (const [_, iframe] of this.iframes.entries()) {
            if (iframe.isContentReady() === false) {
                return false;
            }
        }
        return true;
    }

    /**
     * @returns true if all fields are valid
     */
    public isFormValid(): boolean {
        //mounting is not finished or the form is empty
        if (!this.iframes || this.iframes.size === 0) return false;

        //check if one of the field is not valid then return false immediately
        for (const [_, iframe] of this.iframes.entries()) {
            if (iframe.isContentValid() === false) {
                return false;
            }
        }

        return true;
    }

    /**
     * explicitly asking to validate all fields, the empty value won't be treated as Unknown validity
     * return true if all the fields are valid
     */
    public async validateForm(): Promise<boolean> {
        //mounting is not finished or the form is empty
        if (!this.iframes || this.iframes.size === 0) return false;

        const validatePromises = Array.from(this.iframes.values()).map(
            (iframe) => iframe.validate()
        );
        const validateResults = await Promise.all(validatePromises);
        return validateResults.every((isValid) => isValid);
    }

    /**
     * Refresh the form validity state, if the field validity is changed, the sdk will call this method
     * to refresh the form state validity.
     * @returns isFormValid new form validity
     * @return isFormValidityChanged if the form validity is changed from the last refresh
     */
    public refreshFormValidity(): {
        isFormValid: boolean;
        isFormValidityChanged: boolean;
    } {
        const lastValidity = this.currentFormValidity;
        this.currentFormValidity = this.isFormValid();
        return {
            isFormValid: this.currentFormValidity,
            isFormValidityChanged: lastValidity !== this.currentFormValidity,
        };
    }

    public getParentForm() {
        return this.parentForm;
    }

    private parentForm: HTMLFormElement | null = null;

    /**
     * set the parent form if it is not yet set.
     * find the parent form which contains the field.
     * @param fieldContainer
     */
    public updateParentForm(fieldContainer: HTMLElement) {
        //the parent form has already set
        if (this.parentForm) return;
        this.parentForm = findParentForm(fieldContainer);
    }

    /**
     * the sdk call this when the mounting is finished
     */
    public mountCompleted(iframes: Map<FieldKind, Iframe>): void {
        this.iframes = iframes;
    }
}
