<svelte:options accessors={true} />

<script lang="ts">
    import type {
        IframeMessage,
        SetPlaceHolder,
        SetStyle,
        RequestSubmission,
        SubmissionResult,
        IframeContentConfig,
        ValidityStateChanged,
        InputValueChanged,
        EmptyStateChanged,
        RequestValidate,
        ValidateResult,
    } from '@shared/types';
    import { MessageType } from '@shared/types';
    import { createEventDispatcher } from 'svelte';
    import { focusPreviousElement } from './utils';
    import { replyCrossOrigin, requestCrossOrigin } from '@shared/utils';

    let trapfocus: HTMLSpanElement | undefined;
    let iframe: HTMLIFrameElement | undefined;
    let isReady = false;
    let isValid: boolean | null = null;
    let currentValueMasked: string;

    //props
    export let config: IframeContentConfig;
    //props
    export let iframeUrl: string;

    let src = `${iframeUrl}?uuid=${config.uuid}`;
    const dispatch = createEventDispatcher();

    //handle IframeMessage (capture and handle messages sent by Iframes)
    window.addEventListener(
        'message',
        function (event: MessageEvent<IframeMessage>) {
            //if received non-concerned event
            if (event.data.uuid != config.uuid) return;

            //handle events
            switch (event.data.type) {
                case MessageType.IframeContentConfigRequest:
                    replyCrossOrigin(event, config);
                    break;
                case MessageType.FocusPreviousElementRequest:
                    if (trapfocus) trapfocus.focus();
                    break;

                //from here are dispatchable Messages, we will forward them to the parent container
                case MessageType.IframeContentReady:
                    isReady = true;
                    dispatch(event.data.type, event.data); //forward the dispatchable message to the container
                    break;
                case MessageType.ValidityStateChanged:
                    isValid = (event.data as ValidityStateChanged).isValid;
                    dispatch(event.data.type, event.data); //forward the dispatchable message to the container
                    break;
                case MessageType.InputValueChanged:
                    handleEmptyStateChange(event.data as InputValueChanged);
                    dispatch(event.data.type, event.data); //forward the dispatchable message to the container
                    break;
                case MessageType.FocusStateChanged:
                    dispatch(event.data.type, event.data); //forward the dispatchable message to the container
                    break;

                default:
                    throw new Error(
                        `received a strange message event: ${event.data.type}`
                    );
            }
        }
    );

    function handleEmptyStateChange(event: InputValueChanged) {
        const isOldValueEmpty = !currentValueMasked;
        currentValueMasked = event.valueMasked; //update the currentValueMask
        const isCurrentValueEmpty = !currentValueMasked;

        //check if the empty state has been changed?
        if (isOldValueEmpty === isCurrentValueEmpty) return; //nothing changed

        //the empty state is changed, dispatch the EmptyStateChanged event to the container
        let emptyStateChanged: EmptyStateChanged = {
            type: MessageType.EmptyStateChanged,
            kind: event.kind,
            uuid: event.uuid,
            valueMasked: event.valueMasked,
            isEmpty: isCurrentValueEmpty,
        };
        dispatch(emptyStateChanged.type, emptyStateChanged);
    }

    /**
     * Throw error (crash) if the iframe is not loaded to the DOM
     */
    function guardAgainstUnloadedIframe() {
        if (!iframe || !iframe.contentWindow) {
            throw new Error('iframe is not loaded');
        }
    }

    export function focus() {
        iframe?.focus();
    }

    export function setPlaceHolder(newValue: string): void {
        //this assignment is necessary in case the SDK calls this method before the iframe is loaded
        config.placeHolder = newValue;
        //send newValue to the iframe content => this won't do anything if the iframe is not loaded
        const payload: SetPlaceHolder = {
            type: MessageType.SetPlaceHolder,
            uuid: config.uuid,
            newValue,
        };
        iframe?.contentWindow?.postMessage(payload, '*');
    }

    export function setStyle(newValue: string): void {
        //this assignment is necessary in case the SDK calls this method before the iframe is loaded
        config.style = newValue;
        //send newValue to the iframe content => this won't do anything if the iframe is not loaded
        const payload: SetStyle = {
            type: MessageType.SetStyle,
            uuid: config.uuid,
            newValue,
        };
        iframe?.contentWindow?.postMessage(payload, '*');
    }

    export function isContentReady(): boolean {
        return isReady;
    }
    export function isContentValid(): boolean {
        //no need to check isReady, if the field is not ready then isValid will be null
        return isValid === true;
    }

    /**
     * Explicitly ask for validation. Put the field in valid or invalid state (don't treat empty as Unknown state).
     */
    export async function validate(): Promise<boolean> {
        guardAgainstUnloadedIframe();
        const request: RequestValidate = {
            type: MessageType.RequestValidate,
            uuid: config.uuid,
        };
        const response: ValidateResult = await requestCrossOrigin(
            /*these bang are ok, we already guardAgainstUnloadedIframe()*/
            iframe!.contentWindow!,
            request,
            '*',
            1000
        );
        return response.isValid;
    }

    export function getValidity(): boolean | null {
        //no need to check isReady, if the field is not ready then isValid will be null
        return isValid;
    }
    export async function SubmitForm(
        uuids: string[],
        webkitToken: string
    ): Promise<SubmissionResult> {
        guardAgainstUnloadedIframe();
        const requestSubmission: RequestSubmission = {
            type: MessageType.RequestSubmission,
            uuid: config.uuid,
            uuids,
            webkitToken,
        };
        return requestCrossOrigin(
            /*these bang are ok, we already guardAgainstUnloadedIframe()*/
            iframe!.contentWindow!,
            requestSubmission,
            '*',
            80000
        );
    }
</script>

<span
    bind:this={trapfocus}
    class={config.kind}
    style="position:absolute;left:-9999rem;"
    tabindex="-1"
    on:focus={() => focusPreviousElement(document)}
/>
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<iframe
    bind:this={iframe}
    id={config.uuid}
    name={config.uuid}
    style="width:100%; height:100%; margin:0; padding:0; overflow: hidden; border:0"
    title="Hosted field {config.kind}"
    frameborder="0"
    tabindex="0"
    {src}
/>
